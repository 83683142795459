import React from "react"
import moment from "moment"
//import {API_ROOT} from "gatsby-env-variables"
import { BookContainer } from "../components/BookAppointment/styles"
import { fixedCharges } from "../constants/fee"
import { API_ROOT } from "gatsby-env-variables";
import {
  Input,
  Select,
  Radio,
  DatePicker,
  Row,
  Col,
  Button,
  Switch,
  Form,
  message,
  Modal,
  Spin,
  Icon,
} from "antd"



import { isDefined, isObjectEmpty, isArrayEmpty } from "../utils/util"
import {
  CONSENT_TEXT,
  PAYMENT_ADVICE_TEXT,
} from "../constants/bookingTextConstants"



const { Option } = Select
const { confirm } = Modal


const INITIAL_STATE = {
  fullName: "",
  email: "",
  phone: "",
  gender: "",
  dob: "",
  appointmentDate: "",
  isVideoConsultation: true,
  doctorProfile: {},
  slots: [],
  bookingInProgress: false,
  selectedSlot: "",
  razorpay_payment_id: "",
  razorpay_order_id: "",
  razorpay_signature: "",
}



class BookIndex extends React.Component {
  state = INITIAL_STATE
  formRef = React.createRef();
  componentDidMount() {
    const docId = localStorage.getItem("docId")
    const orgId = localStorage.getItem("orgId")
    const requestBody = {
      docId,
      orgId,
    }

    
  

    fetch(API_ROOT + "spotcare/getdoctordetail", {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(doctorProfileData => doctorProfileData.json())
      .then(doctorProfile => this.setState({ doctorProfile }))

    const script = document.createElement("script")
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    script.async = true
    document.body.appendChild(script)
  }

  handleDobSelect = (date, dateString) => {
    this.setState({ dob: dateString })
  }


  showConsentMessage = () => {
    confirm({
        title: CONSENT_TEXT,
        okType: 'primary1',
        cancelButtonProps: { type: 'danger'},
        okText: 'Agree',
        cancelText: 'Disagree',
        centered: true,
        // onOk: () => this.openRazorPay(),
        onOk:()=> this.bookAppointment(),
        onCancel: () => {this.setState({bookingInProgress: false})}
      });
}

  getDisabledHours = () => {
    var hours = []
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i)
    }
    return hours
  }

  getDisabledMinutes = selectedHour => {
    var minutes = []
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i)
      }
    }
    return minutes
  }

  paymentResponseHandler = response => {
    const {
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
    } = response
    this.setState({
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
    })

    this.bookAppointmentAndPay(this.state)
  }

  bookAppointmentAndPay = async data => {
    const { doctorProfile } = this.state
    let portalCharge = 0
    let gst = 0
    let consultationAmount = 0

    const { orgId } = doctorProfile
    if (doctorProfile.rowsConsultation.consultation_amount < 100) {
      consultationAmount = (
        doctorProfile.rowsConsultation.consultation_amount + fixedCharges
      ).toFixed(2)
      portalCharge = (0).toFixed(2)
      gst = (0).toFixed(2)
    } else {
      consultationAmount = doctorProfile.rowsConsultation.consultation_amount.toFixed(
        2
      )
      portalCharge = (consultationAmount * (12.5 / 100)).toFixed(2)
      gst = (portalCharge * (18 / 100)).toFixed(2)
    }
    const body = {
      name: data.fullName,
      phoneNO: data.phone,
      gender: data.gender,
      dob: data.dob,
      age: this.ageFromDOB(data.dob),
      email: data.email,
      preferredTime: data.selectedSlot,
      checkedIn: "false",
      appointmentDate: data.appointmentDate,
      spotWalkin: "0",
      selectedDocUserMapping: doctorProfile.oumId,
      selectedDocId: data.docId,
      orgId,
      amount: Number(
        Number(consultationAmount) + Number(portalCharge) + Number(gst)
      ),
      consultationAmount: Number(consultationAmount),
      razorpay_payment_id: this.state.razorpay_payment_id,
      razorpay_order_id: this.state.razorpay_order_id,
      razorpay_signature: this.state.razorpay_signature,
      paymentFor: "appointment",
    }

    let bookAppointmentResponse = await fetch(
      API_ROOT + "payment/customurl",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    

    const bookingResult = await bookAppointmentResponse.json()

    if (bookingResult.status) {
      message.success("Appointment Booked Successfully!", 3, () => {
        this.formRef.current.resetFields();
        this.setState({
          fullName: "",
          email: "",
          phone: "",
          gender: "",
          dob: "",
          appointmentDate: "",
          isVideoConsultation: true,
          slots: [],
          bookingInProgress: false,
          selectedSlot: "",
          razorpay_payment_id: "",
          razorpay_order_id: "",
          razorpay_signature: "",
        })
      })
    } else {
      message.error("Not Booked! Slot already occupied.")
      this.formRef.current.resetFields();
      this.setState({
        fullName: "",
        email: "",
        phone: "",
        gender: "",
        dob: "",
        appointmentDate: "",
        isVideoConsultation: true,
        slots: [],
        bookingInProgress: false,
        selectedSlot: "",
        razorpay_payment_id: "",
        razorpay_order_id: "",
        razorpay_signature: "",
      })
    }
  }

  async bookAppointment(){
    console.log("in book appointment")
    const payload = {email:this.state.email,
        name: this.state.fullName,
        phoneNO: this.state.phone,
        gender: this.state.gender, 
        dob: this.state.dob,
        age:this.ageFromDOB(this.state.dob),
        checkedIn: "false",
        preferredTime: this.state.selectedSlot,
        appointmentDate: this.state.appointmentDate,
        spotWalkin: 0,
        selectedDocUserMapping: this.state.doctorProfile.oumId,
        selectedDocId: this.state.doctorProfile.docId,
        orgId:this.state.doctorProfile.orgId,
        isVideo: this.state.isVideoConsultation.toString(),
        tzOffset:new Date().getTimezoneOffset()*-1,
        originalOrgUserMappingId:this.state.doctorProfile.oumId,
        isPaymentPending:this.state.isVideoConsultation?1:0}
     let appointmentResponse= await fetch(
        API_ROOT + "payment/customurl",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        } 
    );
    const response= await appointmentResponse.json();
        console.log(response)
        if (response.status === true){
        message.success(this.state.isVideoConsultation ? "Payment link sent to your Mobile number and Email":"Appointment booked successfully", 3, () => {
          this.formRef.current.resetFields();
        this.setState({
            fullName: '',
            email: '',
            phone: '',
            gender: '',
            dob: '',
            appointmentDate: '',
            isVideoConsultation: true,
            slots: [],
            bookingInProgress: false,
            selectedSlot: '',
            razorpay_payment_id: '',
            razorpay_order_id: '',
            razorpay_signature: ''
        });

    })}
    else{
        message.error("Not Booked! Something went wrong. Please try again");
        this.formRef.current.resetFields();
        this.setState({
            fullName: '',
            email: '',
            phone: '',
            gender: '',
            dob: '',
            appointmentDate: '',
            isVideoConsultation: true,
            slots: [],
            bookingInProgress: false,
            selectedSlot: '',
            razorpay_payment_id: '',
            razorpay_order_id: '',
            razorpay_signature: ''
        });
    };
}
  

  handleSubmit = e => {
    // alert("entering...")
        this.setState({ bookingInProgress: true });
          if (this.state.doctorProfile.rowsConsultation.has_opted === "1" && this.state.isVideoConsultation) {
            this.showConsentMessage();
          }
          else {
            this.bookAppointment();
          }
  };
  

  getSlots = body => {
    fetch(API_ROOT +"spotcare/slots", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => response.json())
      .then(slotResponse => {
        if(slotResponse.data){
          this.setState({ slots: slotResponse.data })
        } else {
          this.setState({ slots: [] })
        }
      })
  }

  ageFromDOB = dob => {
    const today = new Date()
    const birthdate = new Date(dob)
    if (dob === "" || dob == null) {
      return 0
    }
    let age = today.getFullYear() - birthdate.getFullYear()
    const m = today.getMonth() - birthdate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--
    }
    return age
  }

  handleConsultationDateSelect = (date, dateString) => {
    this.setState({
      appointmentDate: dateString,
    })

    const body = {
      addSlot: null,
      date: dateString,
      timeType:(dateString === new Date().toISOString().slice(0, 10) ? 'today' :undefined),
      tzOffset:new Date().getTimezoneOffset()*-1,
      selectedDoctorUserId: this.state.doctorProfile.oumId
      };
    
    // if(dateString) {
      this.getSlots(body)
    // }
  }

  disabledDateForDOB = current => {
    // Can not select days after today and today
    return current && current > moment().startOf("day")
  }

  disabledDateForConsultation = current => {
    // Can not select days after today and today
    return current && current < moment().startOf("day")
  }

  render() {
    const { doctorProfile } = this.state
    const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />
    let portalCharge = 0
    let gst = 0
    let consultationAmount = 0

    if (isDefined(doctorProfile) && isObjectEmpty(doctorProfile)) {
      return (
        <div style={{ textAlign: "center", margin: "20%" }}>
          <Spin tip="Loading..." indicator={antIcon} />
        </div>
      )
    }

    const profile = doctorProfile.profile[0]
    const { orgId } = doctorProfile
    const profileImage = profile.imgpath
    const clinicDetails = !isArrayEmpty(doctorProfile.clinic)
      ? doctorProfile.clinic
      : []
    if (doctorProfile.rowsConsultation.consultation_amount < 100) {
      consultationAmount = (
        doctorProfile.rowsConsultation.consultation_amount + fixedCharges
      ).toFixed(2)
      portalCharge = (0).toFixed(2)
      gst = (0).toFixed(2)
    } else {
      consultationAmount = doctorProfile.rowsConsultation.consultation_amount.toFixed(
        2
      )
      portalCharge = (consultationAmount * (12.5 / 100)).toFixed(2)
      gst = (portalCharge * (18 / 100)).toFixed(2)
    }

    const hasOptedVideoConsultation = doctorProfile.rowsConsultation.has_opted


    
    return (
      <BookContainer>
        <div id="bookAppointment">
          <Row>
            <Col sm={8} md={12} lg={12}>
              <div id="bookappointmentdoctorprofile_container">
                <div className="profile-container">
                  <div className="profile-data-container">
                    <div className="row">
                      <div className="col-xs-12 col-md-5 full-width">
                        <div
                          className="sh-heading sh-heading-style1"
                          id="heading-QAUZhdmpKV"
                        >
                          <div className="mb-3">
                            <img
                              src={profileImage}
                              className="profile-image-style border"
                              alt="Doctor profile picture"
                              style={{ maxHeight: "230px" }}
                            />
                          </div>
                        </div>
                      </div>
                      {isDefined(orgId) ? (
                        <div className="col-xs-12 col-md-7">
                          <h2 className="mon-font sh-heading-content size-xl full-width">
                            <b style={{ fontFamily: "Montserrat" }}>
                              {profile.name}
                            </b>
                          </h2>
                          <div className="sh-heading" id="heading-bOatMNFGmp">
                            <div className="sh-element-margin">
                              <h2
                                className="mon-font sh-heading-content size-custom text-left mon-font"
                                style={{ fontSize: "16px" }}
                              >
                                {profile.usertype}
                              </h2>
                            </div>
                            <p className="mon-font">
                              <strong>{clinicDetails[0].name}</strong>
                              <br />
                              <span
                                style={{ fontSize: "14px", color: "#363636" }}
                              >
                                {clinicDetails[0].address}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : null}
                      <div
                        className="sh-empty-space vc_empty_space sh-empty-space-bPSVpZhkHG"
                        style={{ height: "20px" }}
                      ></div>
                      {isDefined(orgId) ? (
                        <div className="wpb_text_column wpb_content_element">
                          <p className="text-justify mon-font">{profile.bio}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div id="bookappointmentform_container_main">
                <div className="form-container">
                  <div className="form-input-container">
                    <h1 className="h1">
                      <span className="byc1">Book your consultation</span>
                    </h1>
                    <Form  ref={this.formRef}
                      onFinish={this.handleSubmit}
                      className="consultation-form"
                    >
                      <Row>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter name**",
                            },
                            {
                              pattern:/^[a-zA-Z ]{3,} *$/,
                              message: "Please enter a valid name**",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            
                            placeholder="Full Name"
                            onChange={e =>
                              this.setState({ fullName: e.target.value })
                            }
                            // onKeyPress={ event =>
                            //   {
                            //       if (/!\w{3,}/.test( event.key ) )
                                  
                            //       {
                            //           event.preventDefault()
                            //       }
                            //   } }
                          required
                         
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email**",
                            },
                            {
                              pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                message: "Please enter a valid email**",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                          
                            placeholder="Email "
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message:"Please enter Number**",

                            },
                            {
                              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                              message: "Please enter a valid phone number**",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            
                            placeholder=" Phone"
                            onChange={e =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select your gender**!",
                            },
                          ]}
                        >
                          <Radio.Group
                            placeholder="Gender"
                            buttonStyle="solid"
                            className="select-input"
                            onChange={e =>
                              this.setState({ gender: e.target.value })
                            }
                          >
                            <Radio.Button value="male">Male</Radio.Button>
                            <Radio.Button value="female">Female</Radio.Button>
                            <Radio.Button value="other">Other</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item name=" date" 
                        rules={[
                          { 
                          required: true,
                        message: "Please pick your  date your date of birth**!",
                        },
                        ]}>
                          <DatePicker
                            placeholder="Date of Birth (YYYY-MM-DD)"
                            className="date-input"
                            
                            disabledDate={this.disabledDateForDOB}
                            showToday={false}
                            onChange={this.handleDobSelect}
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please pick your consultation date**!",
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Consultation date (YYYY-MM-DD)"
                            className="date-input"
                            
                            disabledDate={this.disabledDateForConsultation}
                            showToday={false}
                            onChange={this.handleConsultationDateSelect}
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="slot"
                          rules={[
                            {
                              required: true,
                              message: "Please pick your consultation slot!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Slot"
                            className="select-input1"
                             onChange={selectedSlot =>
                              this.setState({ selectedSlot })
                            }
                          > 
                            {this.state.slots.map((slot, i) => (
                              <Option key={i} value={slot}>
                                {slot}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Row>
                    
                      {hasOptedVideoConsultation ? (
                        <>
                          <div  class="col-xs-12">
                            <Col
                              sm={{ span: 12 }}
                              lg={{ span: 6 }}
                            >
                              
                              <Switch
                                checked={this.state.isVideoConsultation}
                                onChange={() =>
                                  this.setState({
                                    isVideoConsultation: !this.state
                                      .isVideoConsultation,
                                  })
                                }
                              />
                             
                              <h4>Video Consultation</h4>
                            </Col>
                          </div>
                         
                          
                          {this.state.isVideoConsultation ? (
                            <div class="full-width">
                              <p className="p-Down">
                                Consultation Charges: ₹ {consultationAmount} ,
                                Service fees: ₹ {portalCharge} , GST: ₹ {gst}
                              </p>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      <div class="full-width">
                        <Col
                          sm={{ span: 12}}
                          lg={{ span: 6}}
                        >
                          <Button
                            loading={this.state.bookingInProgress}
                            className="submit-button-style" 
                            htmlType="submit"
                            size="large"
                            type="primary"
                            shape="round"
                            >
                            {this.state.isVideoConsultation
                              ? "Pay & Book"
                              : "Book Appointment"}
                          </Button>
                        </Col>
                      </div>
                      
                      <Row>
                        {this.state.isVideoConsultation ? (
                          <div>
                            <span style={{ color: "#000000", fontWeight: 600 }}>
                              Important Note:&nbsp;
                            </span>
                            <span style={{ color: "#0078aa", fontWeight: 600 }}>
                              {PAYMENT_ADVICE_TEXT}
                            </span>
                          </div>
                        ) : null}
                      </Row>
                    </Form >
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </BookContainer>
    )
  }
}
export default BookIndex
